import "@styles/app.sass"
import Swiper, {Navigation, Pagination} from "swiper";

(() => { // Hiding header
    const header = document.querySelector('[data-page-header]') as HTMLElement;
    const headerOffset = header.offsetTop - window.scrollY,
        headerHeight = (header.children[0] as HTMLElement)?.offsetHeight ?? 0;

    let lastScroll = 0;

    const hidingOnScroll = () => {
        const currentScroll = window.scrollY;

        if (currentScroll > headerOffset + headerHeight) {
            header.classList.add('is-sticked')
            header.classList[currentScroll > lastScroll ? 'add' : 'remove']('is-up')
            document.body.classList[currentScroll > lastScroll ? 'remove' : 'add']('is-sticked-header')
        } else {
            header.classList.remove('is-sticked')
            header.classList.remove('is-up')
            document.body.classList.remove('is-sticked-header')
        }

        lastScroll = currentScroll;
    }

    //hidingOnScroll();
    //window.addEventListener('scroll', () => hidingOnScroll(), { passive: true });

    const navOpener = document.querySelector('[data-nav-opener]')
    const toggleNav = () => {
        document.body.classList.toggle('u-hiddenOverflow');
        navOpener.closest('[data-page-header]')?.classList.toggle('is-opened');
    }

    navOpener.addEventListener("click", toggleNav)
    header.querySelectorAll('a[href^="#"]')
        .forEach(link => link.addEventListener("click", () => {
            document.body.classList.remove('u-hiddenOverflow');
            navOpener.closest('[data-page-header]')?.classList.remove('is-opened');
        }))
})();

(() => { // Testimoanil swiper init
    const swiperEl = document.querySelector('[data-testimonial-swiper]') as HTMLElement
    if (!swiperEl) return

    new Swiper(swiperEl, {
        slidesPerView: 1,
        autoHeight: true,
        modules: [ Navigation, Pagination ],
        navigation: {
            nextEl: '[data-testimonials-next]',
            prevEl: '[data-testimonials-prev]',
        },
        pagination: {
            el: '[data-testimonials-pagination]',
            type: 'bullets'
        },
    })
})();

(() => { // Form utilities
    const floatLabel = (el: HTMLSelectElement | HTMLInputElement) => {
        el?.classList.toggle('has-value', el.value !== "");
    }

    document.querySelectorAll('select').forEach(select => {
        select.addEventListener("change", () => floatLabel(select))
        floatLabel(select)
    })
})();